import React, { useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MyContext } from '../context/Context';
import { db, storage } from '../firebase/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

export const AdminDashboard = () => {
  const { admLogeado } = useContext(MyContext);

  const initialNoteState = {
    tipo: '',
    title: '',
    imgIdPrincipal: '',
    idImgNotes: '',
    epigrafoImgPrincipal: '',
    subtitulo: '',
    subTitle: '',
    autor: '',
    textContent1: '',
    updateUser: '',
    descripcionNota: ''
  };

  const [addNote, setAddNote] = useState(initialNoteState);
  const [fieldCount, setFieldCount] = useState({
    textContent: 1,
    cita: 1,
    subtitulo: 1,
    imgSecundario: 1,
    epigrafoImgSecundario: 1,
  });

  if (admLogeado === undefined) return <p>Cargando...</p>;
  if (!admLogeado) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddNote((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAddField = (fieldType) => {
    setFieldCount((prevCount) => {
      const newCount = prevCount[fieldType] + 1;

      setAddNote((prevNote) => ({
        ...prevNote,
        [`${fieldType}${newCount}`]: '',
        ...(fieldType === 'imgSecundario' && { [`epigrafoImgSecundario${newCount}`]: '' }),
      }));

      return { ...prevCount, [fieldType]: newCount };
    });
  };

  const handleImageChange = async (e, fieldKey) => {
    const file = e.target.files[0];
    if (file) {
      const idUnica = uuidv4();
      const imageRef = ref(storage, idUnica);

      try {
        await uploadBytes(imageRef, file);
        const url = await getDownloadURL(imageRef);
        setAddNote((prevState) => ({ ...prevState, [fieldKey]: idUnica }));
        console.log('Imagen subida exitosamente:', url);
      } catch (error) {
        console.error('Error al subir imagen:', error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const idUnica = uuidv4();
    const noteRef = doc(db, 'NotaCategoria', idUnica);

    try {
      await setDoc(noteRef, addNote);
      console.log('Datos enviados exitosamente');
      setAddNote(initialNoteState);
      setFieldCount({
        textContent: 1,
        cita: 1,
        subtitulo: 1,
        imgSecundario: 1,
        epigrafoImgSecundario: 1,
      });
    } catch (error) {
      console.error('Error al enviar datos:', error);
    }
  };

  const getLabel = (key) => {
    if (key.startsWith('textContent')) return 'Párrafo';
    if (key.startsWith('cita')) return 'Cita';
    if (key.startsWith('subtitulo')) return 'Subtítulo';
    if (key.startsWith('imgSecundario')) return 'Imagen Secundaria';
    if (key.startsWith('epigrafoImgSecundario')) return 'Epígrafe de Imagen';
    return key;
  };

  return (
    <div className="d-flex text-white">
      <form className="mt-4 pt-4 w-50 d-flex justify-content-center" onSubmit={handleSubmit}>
        <div className="d-flex flex-column ms-4 rounded-2 p-4 bg-dark w-100">
          <div className="text-center">
            <p className="fs-4">Subir Nota</p>
          </div>

          {/* Campos principales */}
          {[
            { label: 'Tipo', name: 'tipo', type: 'text' },
            { label: 'Título', name: 'title', type: 'text' },
            { label: 'Descripción Nota', name: 'descripcionNota', type: 'text' },
            { label: 'Subtítulo Principal', name: 'subTitle', type: 'text' },
            { label: 'Autor', name: 'autor', type: 'text' },
          ].map(({ label, name, type }) => (
            <div key={name} className="m_ctn_input_card_note d-flex my-2">
              <label>{label}:</label>
              <input
                type={type}
                name={name}
                value={addNote[name]}
                onChange={handleChange}
                className="rounded-2 w-100"
              />
            </div>
          ))}

          {/* Imagen principal */}
          <div className="m_ctn_input_card_note d-flex my-2">
            <label>Imagen Principal:</label>
            <input
              type="file"
              onChange={(e) => handleImageChange(e, 'idImgNotes')}
              className="rounded-2 w-100"
            />
          </div>

          <div className="m_ctn_input_card_note d-flex my-2">
            <label>Epígrafe Imagen Principal:</label>
            <input
              type="text"
              name="epigrafoImgPrincipal"
              value={addNote.epigrafoImgPrincipal}
              onChange={handleChange}
              className="rounded-2 w-100"
            />
          </div>

          {/* Campos dinámicos */}
          {Object.keys(addNote).map((key) => {
            if (
              key.startsWith('textContent') ||
              key.startsWith('cita') ||
              key.startsWith('subtitulo') ||
              key.startsWith('imgSecundario') ||
              key.startsWith('epigrafoImgSecundario')
            ) {
              return (
                <div key={key} className="m_ctn_input_card_note d-flex my-2">
                  <label>{getLabel(key)}:</label>
                  {key.startsWith('imgSecundario') ? (
                    <input
                      type="file"
                      name={key}
                      onChange={(e) => handleImageChange(e, key)}
                      className="rounded-2 w-100"
                    />
                  ) : (
                    <textarea
                      name={key}
                      value={addNote[key]}
                      onChange={handleChange}
                      className="rounded-2 w-100"
                    />
                  )}
                </div>
              );
            }
            return null;
          })}

          {/* Botones para agregar campos */}
          <div className="d-flex justify-content-between mt-4">
            {['textContent', 'cita', 'subtitulo', 'imgSecundario'].map((fieldType) => (
              <button
                key={fieldType}
                type="button"
                onClick={() => handleAddField(fieldType)}
                className="btn btn-primary text-white"
              >
                Agregar {getLabel(fieldType)}
              </button>
            ))}
          </div>

          {/* Botón de guardar */}
          <button type="submit" className="btn btn-success w-100 mt-4">
            Guardar Nota
          </button>
        </div>
      </form>
    </div>
  );
};