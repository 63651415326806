import React, { createContext, useState, useEffect, useRef } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const MyContext = createContext();

const MyProvider = ({ children }) => {
  const [value, setValue] = useState('default');
  const [admLogeado, setAdmLogeado] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [popUp, setPopUp] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAdmLogeado(true);
        setUserEmail(user.email);
      } else {
        setAdmLogeado(false);
        setUserEmail(null);
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  //reproductor
  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };


  //deslogearme
  const handleLogout = async () => {
    console.log("Cerrando sesión");
    try {
      await signOut(auth);
      navigate('/login');
      console.log("Sesión cerrada exitosamente");
    } catch (error) {
      console.error("Error al cerrar sesión: ", error);
    }
  };
  return (
    <MyContext.Provider value={{ value, setValue, admLogeado, setAdmLogeado, userEmail, setUserEmail, popUp, setPopUp, handleLogout, isPlaying, setIsPlaying, audioRef, handlePlayPause }}>
      {children}
      {/*/ <audio ref={audioRef} src="https://tolkien.republicahosting.net:1994/live" />*/}
      <audio ref={audioRef} controls>
        <source src="https://server.streamcasthd.com/8460/stream" type="audio/mpeg" />
        Tu navegador no soporta el audio en streaming.
      </audio>
    </MyContext.Provider>
  );
};

export { MyContext, MyProvider };
